/*

  c o n t e n t
  Content

  :description:
  The content component allows for simple control over the "container" element for a given page component.

*/

//
//  :react & redux:
import React from 'react'

//
//  :component:
export const Content = props => {
  return <div className={`eky-content h-full w-full flex flex-col flex-1 justify-center items-center`}>{props.children}</div>
}
