/*

  a p i  -  A P I
  api - API

  :description:
  A file to hold our functions that reach out to the API for a response.

  Note, these should all be marshalled through redux actions.

*/

//
//  :react-redux:
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

//
//  :code:
import {
  getDiwaliGreeting,
  patchPublishDiwaliGreetingImage,
  postCreateDiwaliGreeting,
  uploadDiwaliImageUpload,
} from './apiAPI'

//
//  :statics:

//
//  :state:
const initialState = {
  postCreateDiwaliGreetings: {
    status: 'idle',
    requesting: false,
    error: null,
    response: [],
  },

  getDiwaliGreetings: {
    status: 'idle',
    requesting: false,
    error: null,
    response: [],
  },

  postDiwaliImageUpload: {
    status: 'idle',
    requesting: false,
    error: null,
    response: [],
  },

  patchPublishDiwaliGreetingImage: {
    status: 'idle',
    requesting: false,
    error: null,
    response: [],
  },
}

export const createDiwaliGreeting = createAsyncThunk('api/post-create-diwali-greeting', async greetingPayload => {
  const greetingObject = await postCreateDiwaliGreeting(greetingPayload)
  return greetingObject
})

export const getDiwaliGreetingAsync = createAsyncThunk('api/get-diwali-greeting', async uniqueId => {
  const greetingObject = await getDiwaliGreeting(uniqueId)
  return greetingObject
})

export const postDiwaliImage = createAsyncThunk('api/post-diwali-image', async args => {
  const { file } = args
  const uploadResponse = await uploadDiwaliImageUpload(file)
  return uploadResponse
})

export const patchPublishDiwaliGreetingImageAsync = createAsyncThunk('api/patch-publish-image', async args => {
  const { id, extension } = args
  const publishResponse = await patchPublishDiwaliGreetingImage(id, extension)
  return publishResponse
})

export const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      //
      //  :get-eyekandy-products:
      .addCase(createDiwaliGreeting.pending, state => {
        state.postCreateDiwaliGreetings.status = 'requesting'
        state.postCreateDiwaliGreetings.requesting = true
        state.postCreateDiwaliGreetings.error = null
      })
      .addCase(createDiwaliGreeting.fulfilled, (state, action) => {
        state.postCreateDiwaliGreetings.status = 'done'
        state.postCreateDiwaliGreetings.requesting = false
        state.postCreateDiwaliGreetings.response = action.payload
      })
      .addCase(createDiwaliGreeting.rejected, (state, action) => {
        state.postCreateDiwaliGreetings.status = 'error'
        state.postCreateDiwaliGreetings.requesting = false
        state.postCreateDiwaliGreetings.error = action.error
      })

      //
      //
      .addCase(getDiwaliGreetingAsync.pending, state => {
        state.getDiwaliGreetings.status = 'requesting'
        state.getDiwaliGreetings.requesting = true
        state.getDiwaliGreetings.error = null
      })
      .addCase(getDiwaliGreetingAsync.fulfilled, (state, action) => {
        state.getDiwaliGreetings.status = 'done'
        state.getDiwaliGreetings.requesting = false
        state.getDiwaliGreetings.response = action.payload
      })
      .addCase(getDiwaliGreetingAsync.rejected, (state, action) => {
        state.getDiwaliGreetings.status = 'error'
        state.getDiwaliGreetings.requesting = false
        state.getDiwaliGreetings.error = action.error
      })

      //
      //
      .addCase(postDiwaliImage.pending, state => {
        state.postDiwaliImageUpload.status = 'requesting'
        state.postDiwaliImageUpload.requesting = true
        state.postDiwaliImageUpload.error = null
      })
      .addCase(postDiwaliImage.fulfilled, (state, action) => {
        state.postDiwaliImageUpload.status = 'done'
        state.postDiwaliImageUpload.requesting = false
        state.postDiwaliImageUpload.response = action.payload
      })
      .addCase(postDiwaliImage.rejected, (state, action) => {
        state.postDiwaliImageUpload.status = 'error'
        state.postDiwaliImageUpload.requesting = false
        state.postDiwaliImageUpload.error = action.error
      })

      //
      //
      .addCase(patchPublishDiwaliGreetingImageAsync.pending, state => {
        state.patchPublishDiwaliGreetingImage.status = 'requesting'
        state.patchPublishDiwaliGreetingImage.requesting = true
        state.patchPublishDiwaliGreetingImage.error = null
      })
      .addCase(patchPublishDiwaliGreetingImageAsync.fulfilled, (state, action) => {
        state.patchPublishDiwaliGreetingImage.status = 'done'
        state.patchPublishDiwaliGreetingImage.requesting = false
        state.patchPublishDiwaliGreetingImage.response = action.payload
      })
      .addCase(patchPublishDiwaliGreetingImageAsync.rejected, (state, action) => {
        state.patchPublishDiwaliGreetingImage.status = 'error'
        state.patchPublishDiwaliGreetingImage.requesting = false
        state.patchPublishDiwaliGreetingImage.error = action.error
      })
  },
})

//
//  :get-eyekandy-products:
export const selectPostCreateDiwaliGreetingsStatus = state => state.api.postCreateDiwaliGreetings.status
export const selectPostCreateDiwaliGreetingsRequesting = state => state.api.postCreateDiwaliGreetings.requesting
export const selectPostCreateDiwaliGreetingsError = state => state.api.postCreateDiwaliGreetings.error
export const selectPostCreateDiwaliGreetingsResponse = state => state.api.postCreateDiwaliGreetings.response

//
//
export const selectGetDiwaliGreetingsStatus = state => state.api.getDiwaliGreetings.status
export const selectGetDiwaliGreetingsRequesting = state => state.api.getDiwaliGreetings.requesting
export const selectGetDiwaliGreetingsError = state => state.api.getDiwaliGreetings.error
export const selectGetDiwaliGreetingsResponse = state => state.api.getDiwaliGreetings.response

//
//
export const selectPatchPublishDiwaliGreetingImageStatus = state => state.api.patchPublishDiwaliGreetingImage.status
export const selectPatchPublishDiwaliGreetingImageRequesting = state => state.api.patchPublishDiwaliGreetingImage.requesting
export const selectPatchPublishDiwaliGreetingImageError = state => state.api.patchPublishDiwaliGreetingImage.error
export const selectPatchPublishDiwaliGreetingImageResponse = state => state.api.patchPublishDiwaliGreetingImage.response

//
//  :exports:
export default apiSlice.reducer
