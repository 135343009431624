import { configureStore } from '@reduxjs/toolkit'
import apiSlice from '../features/api/apiSlice'
import uploadtoS3Slice from '../features/upload-to-s3/uploadToS3Slice'

export const store = configureStore({
  reducer: {
    api: apiSlice,
    uploadToS3: uploadtoS3Slice,
  },
})
