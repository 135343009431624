//
//  :react & redux:
import React, { useEffect, useState } from 'react'
import { Content } from '../components/Content'
import { useSearchParams } from 'react-router-dom'
import { isDev } from '../js/utils'

//
//  :components:

//
//  :component:
export const DesktopLandingPage = props => {
  
  const [searchParams] = useSearchParams(window.location.href)
  const [designVersion, setDesignVersion] = useState('12')
  const [screenHeight, setScreenHeight] = useState(window.screen.height)
  const [environment, setEnvironment] = useState('staging')

  //
  //  :useEffects:
  useEffect(() => {
    if (searchParams.has('v')) {
      setDesignVersion(searchParams.get('v'))
    } else {
      setDesignVersion('12')
    }
  }, [searchParams])
  useEffect(() => {
    setScreenHeight(window.screen.height)
  }, [])
  useEffect(() => {
    isDev() ? setEnvironment('staging') : setEnvironment('main')
  }, [])

  return (
    <>
      <Content>
        <div className="eky-diwali-coming-soon-container w-screen h-screen relative flex flex-col overflow-hidden">
          <img
            className="absolute bottom-0 left-[8rem] lg:left-[18%] xl:left-[25%] 2xl:left-[35%]"
            src="https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/dewars-diwali-coming-soon-mandala-002.png"
            alt=""
          />
          <img
            className={`absolute top-0 left-0 max-w-[17rem] ${screenHeight < 650 ? 'md:max-w-[27rem]' : 'md:max-w-none'} `}
            src="https://cdn.pointandplace.com/services/dewars-diwali-app/assets/dewars-diwali-title-002.svg"
            alt=""
          />
          <img
            className="absolute right-0 bottom-[4rem] lg:bottom-[82px] lg:right-[28px] 2xl:right-[3rem] xl:bottom-[70px] z-[2] max-h-[25rem] lg:max-h-[35rem] xl:max-h-[44rem] 2xl:max-h-[55rem]"
            src="https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/dewars-diwali-coming-soon-bottle-004.png"
            alt=""
          />

          <div className="w-full h-[139px] lg:h-[187px] bg-[#7B4797] absolute bottom-[7rem] md:bottom-[110px] uppercase flex items-center justify-evenly border-y-4 border-[#E95E0F]">
            <div>
              <img className='w-[80%] lg:w-[100%]' src={`https://cdn.pointandplace.com/services/dewars-diwali-app/assets/qrs/dewars-diwali-${environment}-qr-${designVersion}-001.svg`} alt="" />
            </div>
            <p className="text-white text-[25px] lg:text-[30px] xl:text-[40px] text-center w-[19rem] lg:w-[24rem] xl:w-[32rem] leading-none">
            Scan QR code to<br></br>experience Dewar’s Diwali<br></br>in Augmented Reality
            </p>
            <div className='w-[9rem] lg:w-[11rem] xl:w-[18rem]'></div>
            <div className='w-[180px] lg:w-[200px] xl:w-[18rem]'></div>
          </div>

          <div className="w-full flex justify-center items-center uppercase absolute bottom-[4rem] md:bottom-2"> 
            <p className="text-white text-[8px] md:text-[12px]">
              DRINK DEWAR’S RESPONSIBLY. DEWAR’S, ITS TRADE DRESS AND THE CELTIC LOGO ARE TRADEMARKS.
            </p>
          </div>
        </div>
      </Content>
    </>
  )
}
