import React, { useState, useRef } from 'react'
import { getDiwaliGreetingAsync } from '../features/api/apiSlice'
import { isDev } from '../js/utils'

const CodeEntry = ({
  SetValidUniqueCodeInput,
  setShowSpinner,
  setFinalExperienceURL,
  dispatch,
  designVersion,
  setHasCheckedCode,
  setUniqueCode,
  triggerEvent
}) => {
  const [code, setCode] = useState('')
  const inputRef = useRef(null)

  const validateUniqueCodeAndNavigateToExperience = async codeInput => {
    if (codeInput.length !== 5) {
      return
    }
    SetValidUniqueCodeInput(false)
    let isValid = true

    setShowSpinner(true)
    const greeting = await dispatch(getDiwaliGreetingAsync(codeInput))
    setShowSpinner(false)

    if (!greeting.payload) {
      isValid = false
    }
    if (greeting.payload?.status >= 200) {
      isValid = true
    }

    let safeUniqueCode = String(codeInput).toUpperCase()
    let safeDesignVersion = String(designVersion)
    let environment = String(isDev() ? 'dev' : 'prod')

    //
    //  make sure we have dev/prod split
    const url = isDev()
      ? 'https://diwali-digital-gifting-dewars-com-staging.bacardistaging.com'
      : 'https://diwali-digital-gifting.dewars.com'

    //
    //  Update location href
    const finalURL = `${url}/experience/index.html?v=${encodeURIComponent(safeDesignVersion)}&c=${encodeURIComponent(
      safeUniqueCode
    )}&e=${encodeURIComponent(environment)}`

    if (isValid) {
      setUniqueCode(codeInput)
      setFinalExperienceURL(finalURL)
      SetValidUniqueCodeInput(true)
      triggerEvent('page_view', 'receive_your_gift', '02', '/code_successful', 'Looks Good Lets Celebrate')
    } else {
      SetValidUniqueCodeInput(false)
    }

    setHasCheckedCode(true)
  }

  const handleInputChange = e => {
    const value = e.target.value
    if (/^[a-zA-Z0-9]*$/.test(value) && value.length <= 5) {
      setCode(value)
    }
    setHasCheckedCode(false)
    
    if (value.length === 5) {
      validateUniqueCodeAndNavigateToExperience(value)
    }
  }

  const handleDotClick = () => {
    inputRef.current.focus()
  }

  const renderDots = () => {
    const dots = []
    for (let i = 0; i < 5; i++) {
      dots.push(
        <span className="dot" key={i} onClick={handleDotClick}>
          {code[i] || (
            <img src="https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/code-entry-dot.svg"></img>
          )}
        </span>
      )
    }
    return dots
  }

  return (
    <div className="container">
      <div className="code-display">{renderDots()}</div>
      <input
        type="text"
        ref={inputRef}
        value={code}
        onChange={handleInputChange}
        maxLength="5"
        style={{ opacity: 0, position: 'absolute', left: '-9999px' }}
        onTouchStart={() => triggerEvent('enter_code', 'receive_your_gift', '01', '/enter_code', 'Enter Your Unique Code')}
      />
    </div>
  )
}

export default CodeEntry
