

export const PAGES = {
  landing: 'landing',
  personalise: 'personalise',
  checkYourMessage: 'checkYourMessage',
  addPhoto: 'addPhoto',
  cropPhoto: 'cropPhoto',
  allSet: 'allSet',
  receiveGift: 'receiveGift',
  termsAndConditions: 'termsAndConditions'
}

export const GTM_EVENT_MAPPING = {
  personalise: 'btn_personalise'
}