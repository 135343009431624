/*

  s p i n n e r
  Spinner

  :description:
  A basic component to represent loading.

*/

//
//  :react & redux:
import React from 'react'
import styles from './Spinner.module.css'

//
//  :component:
export const Spinner = props => {
  return (
    <div className='absolute w-full h-full eky-diwali-spinner z-[4]'>
      <div className={styles.spinner}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
