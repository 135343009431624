import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { uploadAsset, uploadImage, uploadLogo, uploadTexture, uploadThumbnail } from './uploadToS3API'

//
//  TODO: Handle reading the token from local storage on login.
const initialState = {
  uploadLogo: {
    status: 'idle',
    requesting: false,
    error: null,
    response: null,
  },
  uploadImage: {
    status: 'idle',
    requesting: false,
    error: null,
    response: null,
  },
  uploadThumbnail: {
    status: 'idle',
    requesting: false,
    error: null,
    response: null,
  },
  uploadTexture: {
    status: 'idle',
    requesting: false,
    error: null,
    response: null,
  },
}

export const uploadLogoAsync = createAsyncThunk('upload-to-s3/upload-logo', async args => {
  if (!args.file || !args.arid || !args.extension || !args.mime) {
    throw new Error(`error, all arguments are required to call this function: file, arid, extension, mime`)
  }
  const response = await uploadLogo(args.file, args.arid, args.extension, args.mime)
  return response
})

export const uploadImageAsync = createAsyncThunk('upload-to-s3/upload-image', async args => {
  if (!args.file || !args.arid || !args.extension || !args.mime) {
    throw new Error(`error, all arguments are required to call this function: file, arid, extension, mime`)
  }
  const response = await uploadImage(args.file, args.arid, args.extension, args.mime, args.id)
  return response
})

export const uploadThumbnailAsync = createAsyncThunk('upload-to-s3/upload-thumbnail', async args => {
  if (!args.file || !args.arid || !args.extension || !args.mime) {
    throw new Error(`error, all arguments are required to call this function: file, arid, extension, mime`)
  }
  const response = await uploadThumbnail(args.file, args.arid, args.extension, args.mime, args.room, args.location)
  return response
})

export const uploadTextureAsync = createAsyncThunk('upload-to-s3/upload-texture', async args => {
  if (!args.file || !args.arid || !args.extension || !args.mime) {
    throw new Error(`error, all arguments are required to call this function: file, arid, extension, mime`)
  }
  const response = await uploadTexture(args.file, args.arid, args.extension, args.mime, args.room, args.location)
  return response
})

export const uploadAssetAsync = createAsyncThunk('upload-to-s3/upload-asset', async args => {
  //
  //  :step 1:
  //  Validate we have all of the required inputs.
  if (!args.type || !args.room || !args.file) {
    throw new Error(`error, all arguments are required to call this function: type, file, room`)
  }

  //
  //  :step 2:
  //  Use our API to handle getting a presigned URL and uploading it.
  const response = await uploadAsset(args.type, args.room, args.file)
  return response
})

export const uploadtoS3Slice = createSlice({
  name: 'upload-to-s3',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      //
      //  :upload-logo:
      .addCase(uploadLogoAsync.pending, state => {
        state.uploadLogo.status = 'requesting'
        state.uploadLogo.requesting = true
        state.uploadLogo.error = null
        state.uploadLogo.response = null
      })
      .addCase(uploadLogoAsync.fulfilled, (state, action) => {
        state.uploadLogo.status = 'done'
        state.uploadLogo.requesting = false
        state.uploadLogo.response = action.payload
      })
      .addCase(uploadLogoAsync.rejected, (state, action) => {
        state.uploadLogo.status = 'error'
        state.uploadLogo.requesting = false
        state.uploadLogo.error = action.error
        console.error(action.error)
      })

      //
      //  :upload-image:
      .addCase(uploadImageAsync.pending, state => {
        state.uploadImage.status = 'requesting'
        state.uploadImage.requesting = true
        state.uploadImage.error = null
        state.uploadImage.response = null
      })
      .addCase(uploadImageAsync.fulfilled, (state, action) => {
        state.uploadImage.status = 'done'
        state.uploadImage.requesting = false
        state.uploadImage.response = action.payload
      })
      .addCase(uploadImageAsync.rejected, (state, action) => {
        state.uploadImage.status = 'error'
        state.uploadImage.requesting = false
        state.uploadImage.error = action.error
        console.error(action.error)
      })

      //
      //  :upload-thumbnail:
      .addCase(uploadThumbnailAsync.pending, state => {
        state.uploadThumbnail.status = 'requesting'
        state.uploadThumbnail.requesting = true
        state.uploadThumbnail.error = null
        state.uploadThumbnail.response = null
      })
      .addCase(uploadThumbnailAsync.fulfilled, (state, action) => {
        state.uploadThumbnail.status = 'done'
        state.uploadThumbnail.requesting = false
        state.uploadThumbnail.response = action.payload
      })
      .addCase(uploadThumbnailAsync.rejected, (state, action) => {
        state.uploadThumbnail.status = 'error'
        state.uploadThumbnail.requesting = false
        state.uploadThumbnail.error = action.error
        console.error(action.error)
      })

      //
      //  :upload-texture:
      .addCase(uploadTextureAsync.pending, state => {
        state.uploadTexture.status = 'requesting'
        state.uploadTexture.requesting = true
        state.uploadTexture.error = null
        state.uploadTexture.response = null
      })
      .addCase(uploadTextureAsync.fulfilled, (state, action) => {
        state.uploadTexture.status = 'done'
        state.uploadTexture.requesting = false
        state.uploadTexture.response = action.payload
      })
      .addCase(uploadTextureAsync.rejected, (state, action) => {
        state.uploadTexture.status = 'error'
        state.uploadTexture.requesting = false
        state.uploadTexture.error = action.error
        console.error(action.error)
      })
  },
})

//export const {  } = authSlice.actions;

//
//  :upload-logo:
export const selectUploadLogoStatus = state => state.uploadToS3.uploadLogo.status
export const selectUploadLogoRequesting = state => state.uploadToS3.uploadLogo.requesting
export const selectUploadLogoError = state => state.uploadToS3.uploadLogo.error
export const selectUploadLogoResponse = state => state.uploadToS3.uploadLogo.response

//
//  :upload-logo:
export const selectUploadImageStatus = state => state.uploadToS3.uploadImage.status
export const selectUploadImageRequesting = state => state.uploadToS3.uploadImage.requesting
export const selectUploadImageError = state => state.uploadToS3.uploadImage.error
export const selectUploadImageResponse = state => state.uploadToS3.uploadImage.response

//
//  :upload-logo:
export const selectUploadThumbnailStatus = state => state.uploadToS3.uploadThumbnail.status
export const selectUploadThumbnailRequesting = state => state.uploadToS3.uploadThumbnail.requesting
export const selectUploadThumbnailError = state => state.uploadToS3.uploadThumbnail.error
export const selectUploadThumbnailResponse = state => state.uploadToS3.uploadThumbnail.response

//
//  :upload-logo:
export const selectUploadTextureStatus = state => state.uploadToS3.uploadTexture.status
export const selectUploadTextureRequesting = state => state.uploadToS3.uploadTexture.requesting
export const selectUploadTextureError = state => state.uploadToS3.uploadTexture.error
export const selectUploadTextureResponse = state => state.uploadToS3.uploadTexture.response

//
//  :exports:
export default uploadtoS3Slice.reducer
