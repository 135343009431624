/*

  a p i  -  A P I
  api - API

  :description:
  A file to hold our functions that reach out to the API for a response.

  Note, these should all be marshalled through redux actions.

*/

//
//  :builtins:

//
//  :code:
import { getApiUrl } from '../../endpoints'

//
//  :packages:
import axios from 'axios'

const getToken = () => {
  return null
}

//
//  :eyekandy-products:
//  Requests for the /eyekandy-products endpoints.
//  -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -
export const postCreateDiwaliGreeting = async payload => {
  //
  //  :step 1:
  //  Get our uri
  const uri = `${getApiUrl()}/v1/greetings`
  const response = await axios.post(uri, JSON.stringify(payload), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })

  return response.data.id
}

export const getDiwaliGreeting = async uniqueID => {
  if (!uniqueID) {
    return null
  }
  //
  //  :step 1:
  //  Get our uri
  const uri = `${getApiUrl()}/v1/greetings/${uniqueID.toUpperCase()}`

  const response = await axios.get(uri, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })

  const returnResponse = {
    data: response.data,
    status: response.status,
  }
  return returnResponse
}

export const uploadDiwaliImageUpload = async file => {
  //
  //  :step 1:
  //  Break out the data from our File object into variables.
  const name = file.name
  const extension = name.split('.')[name.split('.').length - 1]
  const response = await getPresignedURLForEyekandyVisualAssetUploadv2(extension)

  if (!response) {
    throw new Error(`error, empty response when trying to get the presigned url for uploadFile()`)
  }
  if (!response.url) {
    throw new Error(`error, we did not get a presigned url in the response for uploadFile()`)
  }
  const presignedUrl = response.url

  const fileUploaded = await uploadFileToDestination(file, presignedUrl)
  if (!fileUploaded) {
    throw new Error(`error, uploading to s3 failed`)
  }
  return response
}

export const uploadFileToDestination = async (file, destination) => {
  //
  //  Read the content type from the file.
  const contentType = readContentTypeFromFilename(file.name)
  //
  //  Send the file to s3 from here.
  const axiosOptions = { headers: { 'Content-Type': contentType, 'Access-Control-Allow-Origin': '*' } }
  // eslint-disable-next-line
  const response = await axios.put(destination, file, axiosOptions)
  //
  //  Ok, return true as the file was uploaded.
  return true
}

//
//  :helpers:
const readContentTypeFromFilename = filename => {
  if (!filename) {
    return null
  }
  if (filename.endsWith('.png')) {
    return 'image/png'
  }
  if (filename.endsWith('.jpeg')) {
    return 'image/jpeg'
  }
  if (filename.endsWith('.jpg')) {
    return 'image/jpeg'
  }
}

export const getEyekandyProducts = async () => {
  const uri = `${getApiUrl()}/eyekandy-products`
  const response = await axios.get(uri, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const patchPublishDiwaliGreetingImage = async (id, extension) => {
  const uri = `${getApiUrl()}/v1/images/publish/${id}?extension=${extension}`
  const response = await axios.patch(
    uri,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  return response.data
}

//
//  :visual-assets:
//  Requests for the /visual-assets endpoints.
//  -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -   =   -
export const getPresignedURLForEyekandyVisualAssetUploadv2 = async extension => {
  let uri = `${getApiUrl()}/v1/images/request-upload?extension=${extension}`
  const response = await axios.get(uri, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const getPresignedURLForEyekandyVisualAssetUpload = async (type, arid, extension, mime, roomOrId, location) => {
  let uri = `${getApiUrl()}/visual-assets/upload?type=${type}&arid=${arid}&extension=${extension}&mime=${mime}`
  if (roomOrId) {
    uri += `&room=${roomOrId}`
  }
  if (location) {
    uri += `&location=${location}`
  }
  const response = await axios.get(uri, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}

export const createConfiguredProduct = async configuredProductObject => {
  const uri = `${getApiUrl()}/configured-products`
  const response = await axios.post(uri, JSON.stringify(configuredProductObject), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response.data
}
