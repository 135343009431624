/*

  h o m e
  Home

  :description:
  The Home component.

  :TODOS:

  _functionality_
  - Image upload functionality
  - Express concerns about bad language being used and figure out how we want to deal with it
  - logging
  - re-structure desktop landing page

  :branch status:
  DON'T DEPLOY TO MAIN/PROD - It's fine to merge into dev, just waiting on confirmation from PM

*/

//
//  :react & redux:
import React, { useEffect, useRef, useState } from 'react'

//
//  :components:
import { Content } from '../components/Content'
import { PAGES } from '../js/statics'

//
//  :packages:
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import { useSearchParams } from 'react-router-dom'
import { DesktopLandingPage } from './DesktopLandingPage'
import { isDev } from '../js/utils'
import { useDispatch } from 'react-redux'
import { createDiwaliGreeting, patchPublishDiwaliGreetingImageAsync, postDiwaliImage } from '../features/api/apiSlice'
import { Spinner } from '../components/Spinner'
import CodeEntry from '../components/CodeEntry'

//
//  :component:
export const Home = props => {
  //
  //  :redux:
  const dispatch = useDispatch()

  //
  //  :active page:
  const [activePage, setActivePage] = useState('landing')
  const [showMessageConfirmScreen, setShowMessageConfirmScreen] = useState(false)
  const [showGiftHelpPopup, setShowGiftHelpPopup] = useState(false)
  const [showAllSetHelpPopup, setShowAllSetHelpPopup] = useState(false)
  const [showCropPhotoHelpPopup, setShowCropPhotoHelpPopup] = useState(false)

  //
  //  :inputs:
  const [toInput, setToInput] = useState('')
  const [messageInput, setMessageInput] = useState('')
  const [userMessageInput, setUserMessageInput] = useState('')
  const [fromInput, setFromInput] = useState('')
  const [imageInput, setImageInput] = useState(null)
  const [fileForUpload, setFileForUpload] = useState(null)
  const [messageOption, setMessageOption] = useState('own')

  //
  //  :input validations:
  const [validToInput, setValidToInput] = useState(true)
  const [validMessageInput, setValidMessageInput] = useState(true)
  const [validFromInput, setValidFromInput] = useState(true)
  const [validUniqueCodeInput, SetValidUniqueCodeInput] = useState(false)
  const [hasCheckedCode, setHasCheckedCode] = useState(false)

  //
  //  :url stuff:
  const [designVersion, setDesignVersion] = useState('12')
  const [searchParams] = useSearchParams(window.location.href)
  const [finalExperienceURL, setFinalExperienceURL] = useState(null)

  //
  //  :unique-code:
  //  :TODO:
  //  Create a greeting and set this value
  // eslint-disable-next-line
  const [uniqueCode, setUniqueCode] = useState('0000')

  //
  //  :other:
  const [showSpinner, setShowSpinner] = useState(false)
  const [showCropIcon, setShowCropIcon] = useState(true)
  const [cropper, setCropper] = useState(null)
  const cropperRef = useRef(null)

  //
  //  ==================================================================================================================
  //
  //  :helpers: (we can probably simplify all of this)
  const isLanding = () => {
    return activePage === PAGES.landing
  }
  const isPersonalise = () => {
    return activePage === PAGES.personalise
  }
  const isCheckYourMessage = () => {
    return activePage === PAGES.checkYourMessage
  }
  const isAddPhoto = () => {
    return activePage === PAGES.addPhoto
  }
  const isCropPhoto = () => {
    return activePage === PAGES.cropPhoto
  }
  const isAllSet = () => {
    return activePage === PAGES.allSet
  }
  const isReceiveGift = () => {
    return activePage === PAGES.receiveGift
  }
  const pageExists = page => {
    return typeof PAGES[page] !== 'undefined'
  }
  const resetValidationInputs = () => {
    setValidToInput(true)
    setValidMessageInput(true)
    setValidFromInput(true)
  }
  const validateInputs = () => {
    resetValidationInputs()

    let inputsAreValid = true
    if (!toInput.length > 0) {
      setValidToInput(false)
      inputsAreValid = false
    }

    if (!messageInput.length > 0) {
      setValidMessageInput(false)
      inputsAreValid = false
    }

    if (!fromInput.length > 0) {
      setValidFromInput(false)
      inputsAreValid = false
    }

    return inputsAreValid
  }

  //
  // ==================================================================================================================
  //
  //  :util:
  /*
  const validateUniqueCodeAndNavigateToExperience = async codeInput => {
    if (codeInput.length !== 5) {
      return
    }
    SetValidUniqueCodeInput(true)
    let isValid = true

    setShowSpinner(true)
    const greeting = await dispatch(getDiwaliGreetingAsync(codeInput))
    setShowSpinner(false)

    if (!greeting.payload) {
      isValid = false
    }
    if (greeting.payload?.status >= 200) {
      isValid = true
    }

    let safeUniqueCode = String(codeInput).toUpperCase()
    let safeDesignVersion = String(designVersion)
    let environment = String(isDev() ? 'dev' : 'prod')

    //
    //  make sure we have dev/prod split
    const url = isDev()
      ? 'https://diwali-digital-gifting-dewars-com-staging.bacardistaging.com'
      : 'https://diwali-digital-gifting.dewars.com'

    //
    //  Update location href
    const finalURL = `${url}/experience/index.html?v=${encodeURIComponent(safeDesignVersion)}&c=${encodeURIComponent(
      safeUniqueCode
    )}&e=${encodeURIComponent(environment)}`

    return isValid ? setFinalExperienceURL(finalURL) : SetValidUniqueCodeInput(false)
  }
  */
  const navigateToPage = (page, eventLog, journey, stepIndex, pagePath, pagetitle) => {
    if (page === PAGES.checkYourMessage) {
      if (!validateInputs()) {
        return
      }
    }
    if (pageExists(page)) {
      // window.location.hash = `#${page}`
      if (eventLog !== null) {
        triggerEvent(eventLog, journey, stepIndex, pagePath, pagetitle)
      }
      setActivePage(page)
      setShowSpinner(false)
    }
  }
  const navigateToExperience = (passedUniqueCode = '0000', eventLog, journey, stepIndex, pagePath, pagetitle) => {
    //
    // Ensure passedUniqueCode & designVersion is a string
    let safeUniqueCode = String(passedUniqueCode).toUpperCase()
    let safeDesignVersion = String(designVersion)
    let environment = String(isDev() ? 'dev' : 'prod')

    //
    //  make sure we have dev/prod split
    const url = isDev()
      ? 'https://diwali-digital-gifting-dewars-com-staging.bacardistaging.com'
      : 'https://diwali-digital-gifting.dewars.com'

    //
    //  Update location href
    const finalURL = `${url}/experience/index.html?v=${encodeURIComponent(safeDesignVersion)}&c=${encodeURIComponent(
      safeUniqueCode
    )}&e=${encodeURIComponent(environment)}`

    setFinalExperienceURL(finalURL)
    triggerEvent(eventLog, journey, stepIndex, pagePath, pagetitle)

    const linkEl = document.createElement('a')
    linkEl.href = finalURL
    linkEl.rel = 'noreferrer'

    if (window.open) {
      window.open(finalURL, '_blank')
    } else {
      linkEl.target = '_blank'
      linkEl.click()
    }
  }
  const createGreetingAndNavigateToPage = async (page, formData, eventLog, journey, stepIndex, pagePath, pagetitle) => {
    setShowSpinner(true)

    let imagePath = null
    let IMAGE_ID = null

    //
    //  :step 0:
    //  Upload the image to the temp folder
    if (formData) {
      const payload = {
        file: formData,
      }
      imagePath = await dispatch(postDiwaliImage(payload))
      IMAGE_ID = imagePath.payload.id
    }

    const uri = isDev()
      ? 'https://diwali-digital-gifting-dewars-com-staging.bacardistaging.com'
      : 'https://diwali-digital-gifting.dewars.com'

    //
    //  :step 1:
    //  Form our payload
    //  design, to, from, message, image
    const payload = {
      id: IMAGE_ID,
      design: designVersion,
      to: toInput,
      from: fromInput,
      message: messageInput,
      image: IMAGE_ID ? `${uri}/v1/greetings/${IMAGE_ID}.jpg` : 'null',
    }

    //
    //  :step 2:
    //  Dispatch the create greeting event
    const returnedGreeting = await dispatch(createDiwaliGreeting(payload))
    const greetingID = returnedGreeting.payload

    //
    //  Wait for a short while.
    //await new Promise(r => setTimeout(r, 2000))

    //
    //  :step 3:
    //  Dispatch the image publishing.
    // eslint-disable-next-line
    const imagePublishingResponse = await dispatch(
      patchPublishDiwaliGreetingImageAsync({
        id: greetingID,
        extension: fileForUpload ? fileForUpload.name.split('.').pop() : null,
      })
    )

    //
    //  :step 3:
    //  updates
    setShowSpinner(false)
    setUniqueCode(returnedGreeting.payload)

    //
    //  :step 4:
    //  Navigate
    navigateToPage(page, eventLog, journey, stepIndex, pagePath, pagetitle)
  }

  const triggerEvent = (event, journey, stepIndex, pagePath, pagetitle) => {
    //
    //  GTM
    // if (isDev()) {
    //   return
    // }
    if (window.registerButtonClick && event) {
      window.registerButtonClick(event)
    }
    if (window.pushDataLayer && event && journey && stepIndex && pagePath && pagetitle) {
      window.pushDataLayer(event, journey, stepIndex, pagePath, pagetitle)
    }
  }

  //
  // ==================================================================================================================
  //
  //  :useEffects
  useEffect(() => {
    //
    //  Log the page view here:
    if (activePage === 'landing') {
      window.pushDataLayer('page_view', '-', '-', '/introduction', 'DIWALI Deserves Dewars')
    }
    if (activePage === 'personalise') {
      window.pushDataLayer('page_view', 'give_a_gift', '02', '/write_your_message', 'Write Your Message')
    }
    if (activePage === 'checkYourMessage') {
      window.pushDataLayer('page_view', 'give_a_gift', '03', '/preview_message', 'Check Your Message')
    }
    if (activePage === 'addPhoto') {
      window.pushDataLayer('page_view', 'give_a_gift', '04', '/add_your_photo', 'Add Your Photo')
    }
    if (activePage === 'cropPhoto') {
      window.pushDataLayer('page_view', 'give_a_gift', '05', '/adjust_your_photo', 'Adjust Your Photo')
    }
    if (activePage === 'allSet') {
      window.pushDataLayer('page_view', 'give_a_gift', '06', '/your_code', 'Heres Your Code')
    }
    if (activePage === 'receiveGift') {
      window.pushDataLayer('page_view', 'receive_your_gift', '01', '/enter_code', 'Enter Your Unique Code')
    }
  }, [activePage])

  useEffect(() => {
    //
    // Set the design version based on query parameters, or use '12' as the default
    const defaultVersion = '12'
    const version = searchParams.has('v') ? searchParams.get('v') : defaultVersion

    setDesignVersion(version)
  }, [searchParams])

  useEffect(() => {
    if (messageOption === 'diwali') {
      setMessageInput('HAPPY DIWALI!\nWISHING YOU A PROSPEROUS\nYEAR AHEAD!')
    } else {
      setMessageInput(userMessageInput)
    }
  }, [messageOption, userMessageInput])

  useEffect(() => {
    const initializeCropper = async () => {
      if (imageInput) {
        setTimeout(() => {
          if (!cropperRef.current) {
            return
          }
          const ctx = cropperRef.current.getContext('2d')
          const img = new Image()
          img.src = imageInput
          img.onload = () => {
            cropperRef.current.width = img.width
            cropperRef.current.height = img.height
            ctx.drawImage(img, 0, 0, img.width, img.height)

            //
            //  Create a new 'CropperJS' instance
            const cropperOptions = {
              modal: true,
              guides: false,
              center: false,
              highlight: false,
              background: false,
              autoCrop: true,
              autoCropArea: 1,
              cropBoxMovable: true,
              cropBoxResizable: false,
              viewMode: 3,
              ready() {
                this.cropper.crop()
              },
            }

            // cropper = new Cropper(cropperRef, cropperOptions)
            setCropper(new Cropper(cropperRef.current, cropperOptions))
            setShowCropIcon(true)
          }
        }, 700)
      }
    }
    initializeCropper()
  }, [fileForUpload, imageInput])

  //
  // ==================================================================================================================
  //
  //  :event-handlers:
  const handleImageUpload = event => {
    const file = event.target.files[0]
    if (file) {
      setShowSpinner(true)
      setFileForUpload(file)
      const reader = new FileReader()
      reader.onload = function (e) {
        setImageInput(e.target.result)
        navigateToPage('cropPhoto', 'upload_photo_successful', 'give_a_gift', '04', '/add_your_photo', 'Add Your Photo')
      }
      reader.readAsDataURL(file)
    }
  }
  const emailUniqueCodeEvent = () => {
    const params = new URLSearchParams(window.location.search)

    if (window.SPOTLIGHT) {
      window.SPOTLIGHT.openPromotion('dewarsGTRdiwali24GLB', {
        sourceTag: params.get('sourcetag') ? params.get('sourcetag') : '',
        survey: {
          code: uniqueCode,
        },
      })
    }

    triggerEvent('email_code', 'give_a_gift', '06', '/your_code', 'Heres Your Code')
  }
  const share = async () => {
    triggerEvent('btn_share')
    if (typeof navigator.share === 'undefined' || !navigator.share) {
      return
    } else if (window.location.protocol !== 'https:') {
      return
    } else {
      const url = window.location.href
      const title = "DIWALI DESERVES DEWAR'S"
      const description = "EXPERIENCE DEWAR'S IN AUGMENTED REALITY"

      try {
        await navigator.share({ url: url, title: title, text: description })
      } catch (error) {
        return
      }
    }
  }

  //
  //  ==================================================================================================================
  //
  //  :render functions:
  const landing = () => {
    return (
      <div className="eky-diwali-landing flex flex-col items-center relative w-full h-screen relative uppercase">
        <div className="w-full h-[183px] flex mt-3 bg-[#7B4797] relative py-3 border-y-4 border-[#E95E0F] z-[2]">
          <img
            className="w-full h-full max-w-[238px] h-[134px]"
            src="https://cdn.pointandplace.com/services/dewars-diwali-app/assets/dewars-diwali-title-002.svg"
            alt=""
          />
          <img
            className="w-[40px] h-[40px] absolute top-0 right-0 m-4 z-[5]"
            src="https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/share-icon.svg"
            alt=""
            onClick={e => {
              share()
            }}
          />
        </div>
        <div className="w-auto h-auto fixed bottom-[-9px] right-[473px] scale-[1.1]">
          <img
            className="absolute mandala-group-spin-right max-w-[950px] bottom-0 left-0 mb-[-473px] ml-[-100px]"
            src="https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/landing-mandala-001-blur.png"
            alt=""
          />
          <img
            className="absolute mandala-group-spin-right-faster max-w-[564px] bottom-0 left-0 mb-[-273px] ml-[92px] z-[3]"
            src="https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/landing-mandala-004-blur.png"
            alt=""
          />

          <img
            className="absolute mandala-group-spin-left max-w-[613px] bottom-0 left-0 mb-[-276px] ml-[46px] z-[2]"
            src="https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/landing-mandala-002-blur.png"
            alt=""
          />
          <img
            className="absolute mandala-group-spin-left max-w-[514px] bottom-0 left-0 mb-[-270px] ml-[138px] z-[2]"
            src="https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/landing-mandala-003-blur.png"
            alt=""
          />
        </div>
        <div className="landing-buttons flex flex-col text-white justify-center items-center z-[2] w-full z-[6] fixed bottom-[16px]">
          <div className="p-4 flex flex-col justify-center items-center">
            <button
              className="eky-diwali-button-large landing-button text-xl flex flex-col justify-center items-center z-[5]"
              onClick={() => {
                navigateToPage(
                  'personalise',
                  'give_a_gift',
                  'give_a_gift',
                  '01',
                  '/introduction',
                  'DIWALI Deserves Dewars'
                )
              }}
              onTouchStart={e => {
                e.currentTarget.classList.toggle('press')
              }}
              onTouchEnd={e => {
                e.currentTarget.classList.toggle('press')
              }}
            >
              <p className="eky-button-text-main">GIVE AS A GIFT</p>
            </button>
            <button
              className={`eky-diwali-button-large landing-button my-3 z-[5]`}
              onClick={() => {
                navigateToPage(
                  'receiveGift',
                  'receive_your_gift',
                  'receive_your_gift',
                  '01',
                  '/introduction',
                  'DIWALI Deserves Dewars'
                )
                setHasCheckedCode(false)
              }}
              onTouchStart={e => {
                e.currentTarget.classList.toggle('press')
              }}
              onTouchEnd={e => {
                e.currentTarget.classList.toggle('press')
              }}
            >
              <p className="eky-button-text-main">RECIEVE YOUR GIFT</p>
            </button>
            <button
              className="eky-diwali-button-large-blur landing-button border z-[5]"
              onClick={() =>
                navigateToExperience(
                  '0000',
                  'preview_experience',
                  'give_a_gift',
                  '01',
                  '/introduction',
                  'DIWALI Deserves Dewars'
                )
              }
              onTouchStart={e => {
                e.currentTarget.classList.toggle('press')
              }}
              onTouchEnd={e => {
                e.currentTarget.classList.toggle('press')
              }}
            >
              <p className="eky-button-text-main">PREVIEW EXPERIENCE</p>
            </button>
          </div>
          <div className="text-[12px] cursor-pointer z-[3] text-white underline uppercase">
            <a
              className="underline z-[2]"
              href="https://www.dewars.com/terms-and-conditions/"
              rel="noreferrer"
              target="_blank"
              onClick={() => {
                triggerEvent('terms_and_conditions', '-', '-', '/introduction', 'DIWALI Deserves Dewars')
              }}
            >
              terms & conditions
            </a>
          </div>
          <div className="uppercase text-white text-center text-[12px] z-[3] mt-3 max-w-[257px]">
            <p>DRINK DEWAR'S RESPONSIBLY. DEWAR'S, ITS TRADE DRESS AND THE CELTIC LOGO ARE TRADEMARKS.</p>
          </div>
        </div>
      </div>
    )
  }

  const messageConfirmScreen = () => {
    return (
      <div className="eky-diwali-message-confirm-popup absolute w-screen h-screen z-[9] flex justify-center items-center">
        <div className="absolute w-full h-full bg-black z-[1] opacity-[50%]"></div>
        <div className="eky-popup-content max-w-[355px] max-h-[466px] w-full h-full bg-[#F6F6F6] rounded-[10px] items-center flex flex-col z-[2]">
          <div className="my-8">
            <p className="text-[#A9725D] text-[21px]">CHECK YOUR MESSAGE</p>
          </div>
          <div className="flex flex-col bg-[#7B4797] border-[5px] border-[#E95E0F] w-full h-full max-w-[291px] max-h-[173px] rounded-[10px] items-center justify-center text-center">
            <p className="text-white text-[21px]">TO {toInput}</p>
            <p className="text-white text-[21px] my-1 text-balance">{messageInput}</p>
            <p className="text-white text-[21px]">FROM {fromInput}</p>
          </div>
          <div className="popup-btns flex flex-col mt-8">
            <button
              className="eky-diwali-button-popup-large continue"
              onClick={() => {
                navigateToPage('addPhoto', 'continue', 'give_a_gift', '03', '/preview_message', 'Check Your Message')
                setShowMessageConfirmScreen(false)
              }}
              onTouchStart={e => {
                e.currentTarget.classList.toggle('press')
              }}
              onTouchEnd={e => {
                e.currentTarget.classList.toggle('press')
              }}
            >
              <p className="eky-button-text-main">CONTINUE</p>
            </button>
            <button
              className="eky-diwali-button-popup-large my-3 back"
              onClick={() => {
                setShowMessageConfirmScreen(false)
                triggerEvent('back', 'give_a_gift', '03', '/preview_message', 'Check Your Message')
              }}
              onTouchStart={e => {
                e.currentTarget.classList.toggle('press')
              }}
              onTouchEnd={e => {
                e.currentTarget.classList.toggle('press')
              }}
            >
              <p className="eky-button-text-main">BACK</p>
            </button>
          </div>
        </div>
      </div>
    )
  }

  const personalise = () => {
    const validateMessageAndShowPreviewPopup = () => {
      const inputsAreValid = validateInputs()
      if (inputsAreValid) {
        return setShowMessageConfirmScreen(true)
      }
    }
    return (
      <div className="eky-diwali-personalise flex flex-col items-center w-full h-full uppercase">
        {showMessageConfirmScreen && messageConfirmScreen()}
        <img
          className="absolute bottom-0 w-full"
          src="https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/background-mandala.png"
          alt=""
        />

        <div className="text-[32px] text-white uppercase text-center px-[1rem] py-3 mt-8 leading-[36px] z-[2] bg-[#7B4797] w-full border-y-4 border-[#E95E0F]">
          <p>WRITE YOUR MESSAGE</p>
        </div>
        <div className="flex flex-col form-inputs w-full px-4 py-3 z-[2]">
          <div
            className={`eky-diwali-input-box ${
              validToInput === false ? 'invalid' : ''
            } to max-h-[50px] border-[2px] border-[#E8E8E8]`}
          >
            {/* <p className={`${validToInput ? 'text-black text-[23px]' : 'text-red-600 text-[23px]'}`}>
              TO*
            </p> */}
            <input
              type="text"
              name="to"
              id="input-to"
              placeholder="TO*"
              maxLength={30}
              onChange={e => {
                setToInput(e.target.value)
              }}
              onTouchStart={e => {
                triggerEvent('start_writing', 'give_a_gift', '02', '/write_your_message', 'Write Your Message')
              }}
              value={toInput}
            />
          </div>
          <div
            className={`eky-diwali-input-box ${
              validMessageInput === false ? 'invalid' : ''
            } my-3 border-[2px] border-[#E8E8E8]`}
          >
            {/* <p className={`${validMessageInput ? 'text-black text-[23px]' : 'text-red-600 text-[23px]'}`}>
              Write your message
            </p> */}
            <div className="flex flex-col w-full ">
              <textarea
                className="mt-2"
                type="text"
                name="message"
                id="input-message"
                placeholder="TAP TO TYPE YOUR PERSONALISED MESSAGE HERE*"
                maxLength="50"
                style={{ height: '28px' }}
                onChange={e => {
                  setUserMessageInput(e.target.value)
                }}
                value={messageInput}
              />
              <div className="divider"></div>
              <p className="text-[10px] mt-2 text-[#A9725D]">Maximum 50 characters.</p>
            </div>
          </div>
          <div className="eky-diwali-message-options flex w-full justify-between mb-3">
            <button
              onClick={e => {
                setMessageOption('own')
              }}
              className={`eky-message-option-btn ${messageOption === 'own' ? 'selected' : 'not-selected'} px-[30px]`}
            >
              WRITE YOUR OWN MESSAGE
            </button>
            <button
              onClick={e => {
                setMessageOption('diwali')
              }}
              className={`eky-message-option-btn ${messageOption === 'diwali' ? 'selected' : 'not-selected'} px-[30px]`}
            >
              HAPPY DIWALI MESSAGE
            </button>
          </div>
          <div
            className={`eky-diwali-input-box ${
              validFromInput === false ? 'invalid' : ''
            } from z-[2] max-h-[50px] border-[2px] border-[#E8E8E8]`}
          >
            {/* <p className={`${validFromInput ? 'text-black text-[23px]' : 'text-red-600 text-[23px]'}`}>
              Who is the gift from?
            </p> */}
            <input
              type="text"
              name="from"
              id="input-from"
              placeholder="FROM*"
              onChange={e => {
                setFromInput(e.target.value)
              }}
              value={fromInput}
            />
          </div>
        </div>
        <div className="nav-buttons w-full h-auto z-[2]">
          <div className="flex flex-col items-center h-full w-full">
            <button
              className="eky-diwali-button-large landing-button"
              onClick={() => {
                validateMessageAndShowPreviewPopup()
                triggerEvent('preview_message', 'give_a_gift', '02', '/write_your_message', 'Write Your Message')
              }}
              onTouchStart={e => {
                e.currentTarget.classList.toggle('press')
              }}
              onTouchEnd={e => {
                e.currentTarget.classList.toggle('press')
              }}
            >
              <p className="eky-button-text-main">PREVIEW MESSAGE</p>
            </button>
            <button
              className="eky-diwali-button-large-blur landing-button my-3"
              onClick={() =>
                navigateToPage('landing', 'back', 'give_a_gift', '02', '/write_your_message', 'Write Your Message')
              }
              onTouchStart={e => {
                e.currentTarget.classList.toggle('press')
              }}
              onTouchEnd={e => {
                e.currentTarget.classList.toggle('press')
              }}
            >
              <p className="eky-button-text-main">BACK</p>
            </button>
          </div>
        </div>
      </div>
    )
  }

  const checkYourMessage = () => {
    return (
      <div className="eky-check-your-message flex flex-col items-center w-full h-screen relative p-4 uppercase">
        <img
          className="absolute bottom-0 opacity-[20%] w-full"
          src="https://cdn.pointandplace.com/services/dewars-diwali-app/assets/dewars-diwali-mandala-001.png"
          alt=""
        />

        <div className="text-[36px] text-white uppercase text-center px-[2rem] leading-[36px] z-[2]">
          Check your message
        </div>
        <div className="flex w-full p-3 justify-center">
          <div className="eky-check-your-message-box my-2 z-[2] max-w-[390px] max-h-[350px]">
            <div>
              <p>{toInput},</p>
            </div>
            <div>
              <p>{messageInput}</p>
            </div>
            <div className="flex flex-col">
              <p style={{ lineHeight: 'initial' }}>from, </p>
              {fromInput}
            </div>
          </div>
        </div>
        <div className="nav-buttons w-full mt-2 z-[2]">
          <div className="flex flex-col items-center h-full w-full">
            <button
              className="eky-diwali-button-small landing-button"
              onClick={() => navigateToPage('addPhoto', 'btn_looks-good')}
            >
              <p className="eky-button-text-main">looks good</p>
            </button>
            <button
              className="eky-diwali-button-small landing-button my-2"
              onClick={() => navigateToPage('personalise', 'btn_change')}
            >
              <p className="eky-button-text-main">change message</p>
            </button>
          </div>
        </div>
      </div>
    )
  }

  const addPhoto = () => {
    return (
      <div className="eky-diwali-add-photo flex flex-col items-center w-full h-screen relative uppercase">
        <img
          className="absolute bottom-0 w-full"
          src="https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/background-mandala.png"
          alt=""
        />

        <div className="text-[32px] text-white uppercase text-center px-[1rem] py-3 mt-8 leading-[36px] z-[2] bg-[#7B4797] w-full border-y-4 border-[#E95E0F]">
          <p>ADD YOUR PHOTO</p>
        </div>

        <div className="flex w-full justify-center">
          <div className="eky-diwali-image-upload-container z-[2] my-6 border-2 border-[#E8E8E8]">
            <label htmlFor="file-input">
              <img
                src="https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/dewars-diwali-add-photo-icon-002.svg"
                alt="upload-icon"
                onClick={() => {
                  triggerEvent('upload_photo', 'give_a_gift', '04', '/add_your_photo', 'Add Your Photo')
                }}
              />
            </label>

            <input
              id="file-input"
              type="file"
              className="eky-diwali-file-input hidden"
              onChange={handleImageUpload}
              accept="image/*"
            />
          </div>
        </div>

        <div className="nav-buttons w-full z-[2]">
          <div className="flex flex-col items-center w-full h-full">
            <label
              className="eky-diwali-button-large flex justify-center items-center"
              onClick={() => {
                triggerEvent('upload_photo', 'give_a_gift', '04', '/add_your_photo', 'Add Your Photo')
              }}
              onTouchStart={e => {
                e.currentTarget.classList.toggle('press')
              }}
              onTouchEnd={e => {
                e.currentTarget.classList.toggle('press')
              }}
              htmlFor="file-input-002"
            >
              <p className="eky-button-text-main">UPLOAD PHOTO</p>
            </label>

            <input
              id="file-input-002"
              type="file"
              className="eky-diwali-file-input hidden"
              onChange={handleImageUpload}
              accept="image/*"
            />

            <button
              className="eky-diwali-button-large-blur my-3"
              onClick={() =>
                createGreetingAndNavigateToPage(
                  'personalise',
                  null,
                  'back',
                  'give_a_gift',
                  '04',
                  '/add_your_photo',
                  'Add Your Photo'
                )
              }
              onTouchStart={e => {
                e.currentTarget.classList.toggle('press')
              }}
              onTouchEnd={e => {
                e.currentTarget.classList.toggle('press')
              }}
            >
              <p className={`eky-button-text-main`}>BACK</p>
            </button>
          </div>
        </div>
      </div>
    )
  }

  const cropPhoto = () => {
    if (!imageInput) {
      return navigateToPage('addPhoto')
    }
    // eslint-disable-next-line
    const exportCroppedImage = () => {
      if (!imageInput) {
        return
      }
      //
      //  :todo:
      cropper.getCroppedCanvas({ maxWidth: 700, maxHeight: 700 }).toBlob(
        blob => {
          var file = new File([blob], fileForUpload.name)
          createGreetingAndNavigateToPage(
            'allSet',
            file,
            'continue',
            'give_a_gift',
            '05',
            '/adjust_your_photo',
            'Adjust Your Photo'
          )
        },
        'image/jpeg',
        0.92
      )
    }

    const processCroppedCanvasAndContinue = async () => {
      exportCroppedImage()
    }

    const helpPopup = () => {
      return (
        <div className="eky-diwali-message-confirm-popup absolute w-screen h-screen z-[9] flex justify-center items-center">
          <div className="absolute w-full h-full bg-black z-[1] opacity-[50%]"></div>
          <div className="eky-popup-content max-w-[355px] w-full bg-[#F6F6F6] rounded-[10px] flex flex-col z-[2] p-6">
            <div className="mb-3">
              <p className="text-[#A9725D] text-[21px]">PHOTO EDITING</p>
            </div>
            <div>
              <p className="text-[#6C4537] text-[14px] font-inter">
                Pinch to crop and centre your photo within the circle to your desired position.
              </p>
            </div>
            <div className="popup-btns flex flex-col mt-5 justify-center items-center">
              <button
                className="eky-diwali-button-popup-large continue"
                onClick={() => {
                  setShowCropPhotoHelpPopup(false)
                  triggerEvent('info_close', 'give_a_gift', '05', '/adjust_your_photo', 'Adjust Your Photo')
                }}
                onTouchStart={e => {
                  e.currentTarget.classList.toggle('press')
                }}
                onTouchEnd={e => {
                  e.currentTarget.classList.toggle('press')
                }}
              >
                <p className="eky-button-text-main">GOT IT</p>
              </button>
            </div>
          </div>
        </div>
      )
    }

    const cropIcon = () => {
      return (
        <div className="eky-diwali-crop-icon-container absolute bottom-[29%] z-[5] w-full h-full max-w-[80px] max-h-[111px]">
          <img
            className="eky-diwali-crop-arrow-right absolute right-[7px] top-[2px]"
            src="https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/crop-arrow-right.svg"
            alt=""
          />
          <img
            className="eky-diwali-crop-arrow-left absolute bottom-[42px] left-[3px]"
            src="https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/crop-arrow-left.svg"
            alt=""
          />
          <img
            className="absolute w-full max-w-[53px] max-h-[80px] bottom-0 right-0 rotate-6"
            src="https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/crop-hand.svg"
            alt=""
          />
        </div>
      )
    }

    setTimeout(() => {
      setShowCropIcon(false)
    }, 5000)

    return (
      <div className="eky-diwali-crop-photo flex flex-col items-center w-full h-screen relative">
        {showCropPhotoHelpPopup && helpPopup()}
        <img
          className="absolute bottom-0 w-full"
          src="https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/background-mandala.png"
          alt=""
        />

        <div className="text-[32px] text-white uppercase text-center px-[1rem] py-3 mt-3 leading-[36px] z-[2] bg-[#7B4797] w-full border-y-4 border-[#E95E0F] flex justify-center items-center relative">
          <p className="max-w-[200px]">ADJUST YOUR PHOTO</p>
          <img
            className="w-[35px] h-[35px] absolute right-0 top-0 mr-2 mt-2 z-[5]"
            src="https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/help-icon.svg"
            alt="get help for this page"
            onClick={e => {
              setShowCropPhotoHelpPopup(true)
              triggerEvent('info_open', 'give_a_gift', '05', '/adjust_your_photo', 'Adjust Your Photo')
            }}
          />
        </div>

        <div className="flex w-full justify-center relative">
          <div className="image-upload eky-diwali-image-upload-container-crop z-[2] my-6 z-[2] border-2 border-[#E8E8E8]">
            <canvas ref={cropperRef} className="max-w-full rounded-full w-full h-full" id="canvas"></canvas>
          </div>
          {showCropIcon && cropIcon()}
        </div>

        <div className="nav-buttons w-full z-[2]">
          <div className="flex flex-col items-center">
            <button
              className="eky-diwali-button-large"
              onClick={() => processCroppedCanvasAndContinue()}
              onTouchStart={e => {
                e.currentTarget.classList.toggle('press')
              }}
              onTouchEnd={e => {
                e.currentTarget.classList.toggle('press')
              }}
            >
              <p className="eky-button-text-main">CONTINUE</p>
            </button>
            <button
              className="eky-diwali-button-large-blur my-3"
              onClick={() => {
                navigateToPage(
                  'addPhoto',
                  'change_photo',
                  'give_a_gift',
                  '05',
                  '/adjust_your_photo',
                  'Adjust Your Photo'
                )
                setCropper(null)
                setFileForUpload(null)
              }}
              onTouchStart={e => {
                e.currentTarget.classList.toggle('press')
              }}
              onTouchEnd={e => {
                e.currentTarget.classList.toggle('press')
              }}
            >
              <p className="eky-button-text-main">Change photo</p>
            </button>
          </div>
        </div>
      </div>
    )
  }

  const allSet = () => {
    const onClickCopyToClickBoard = () => {
      if (navigator && uniqueCode) {
        navigator.clipboard.writeText(uniqueCode)
      }
    }

    const helpPopup = () => {
      return (
        <div className="eky-diwali-message-confirm-popup absolute w-screen h-screen z-[9] flex justify-center items-center">
          <div className="absolute w-full h-full bg-black z-[1] opacity-[50%]"></div>
          <div className="eky-popup-content max-w-[355px] w-full bg-[#F6F6F6] rounded-[10px] flex flex-col z-[2] p-6">
            <div className="mb-3">
              <p className="text-[#A9725D] text-[21px]">YOUR CODE</p>
            </div>
            <div>
              <p className="text-[#6C4537] text-[14px] font-inter">
                This is your unique gift code. Email a copy to yourself so you don’t lose it, and don’t forget to write
                it on the box before you give your gift
              </p>
            </div>
            <div className="popup-btns flex flex-col mt-5 justify-center items-center">
              <button
                className="eky-diwali-button-popup-large continue"
                onClick={() => {
                  setShowAllSetHelpPopup(false)
                  triggerEvent('info_close', 'give_a_gift', '06', '/your_code', 'Heres Your Code')
                }}
                onTouchStart={e => {
                  e.currentTarget.classList.toggle('press')
                }}
                onTouchEnd={e => {
                  e.currentTarget.classList.toggle('press')
                }}
              >
                <p className="eky-button-text-main">GOT IT</p>
              </button>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="eky-diwali-all-set flex flex-col items-center w-full h-screen relative">
        {showAllSetHelpPopup && helpPopup()}
        <img
          className="absolute bottom-0 w-full"
          src="https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/background-mandala.png"
          alt=""
        />

        <div className="text-[32px]  text-white uppercase text-center px-[1rem] py-3 mt-8 leading-[36px] z-[2] bg-[#7B4797] w-full border-y-4 border-[#E95E0F] flex justify-center items-center relative">
          <p>HERE'S YOUR CODE</p>
          <img
            className="w-[35px] h-[35px] absolute right-0 mr-3 z-[5]"
            src="https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/help-icon.svg"
            alt="get help for this page"
            onClick={e => {
              setShowAllSetHelpPopup(true)
              triggerEvent('info_open', 'give_a_gift', '06', '/your_code', 'Heres Your Code')
            }}
          />
        </div>

        <div
          className="unique-code w-full max-w-[315px] min-h-[306px] bg-white rounded-lg flex flex-col  items-center my-4 z-[2] pt-[1.5rem] px-4 border-[2px] border-[#E8E8E8]"
          onClick={() => {
            onClickCopyToClickBoard()
          }}
        >
          <p className="text-[#B5978C] text-[18px]">UNIQUE CODE:</p>
          <p className="text-[66px] text-[#51352A] font-medium tracking-[20px]" id="copy-unique-code">
            {uniqueCode}
          </p>

          <div className="eky-diwali-code-banner w-full p-3 bg-[#C01030] absolute mt-[165px] text-white">
            <p className="text-[21px] max-w-[188px] text-center leading-[1.1]">
              DON'T FORGET TO<br></br>WRITE YOUR CODE<br></br> ON THE BOX!
            </p>
            <img
              className="absolute right-[20px] top-[-33px] max-w-[160px]"
              src="https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/code-banner-badge-002.png"
              alt=""
            />
          </div>
        </div>

        <div className="nav-buttons flex justify-center mt-4 z-[2] fixed bottom-[10px]">
          <div className="flex flex-col items-center">
            <button
              className="eky-diwali-button-large"
              onClick={() => emailUniqueCodeEvent()}
              onTouchStart={e => {
                e.currentTarget.classList.toggle('press')
              }}
              onTouchEnd={e => {
                e.currentTarget.classList.toggle('press')
              }}
            >
              <p className="eky-button-text-main">EMAIL CODE</p>
            </button>
            <button
              className="eky-diwali-button-large-blur my-2"
              onClick={() =>
                navigateToExperience(uniqueCode, 'preview_gift', 'give_a_gift', '06', '/your_code', 'Heres Your Code')
              }
              onTouchStart={e => {
                e.currentTarget.classList.toggle('press')
              }}
              onTouchEnd={e => {
                e.currentTarget.classList.toggle('press')
              }}
            >
              <p className="eky-button-text-main">PREVIEW GIFT</p>
            </button>
            <button
              onClick={() =>
                navigateToPage('landing', 'back_to_homepage', 'give_a_gift', '06', '/your_code', 'Heres Your Code')
              }
            >
              <p className="underline text-white text-[12px]">BACK TO HOMEPAGE</p>
            </button>
          </div>
        </div>
      </div>
    )
  }

  const receiveGift = () => {
    const helpPopup = () => {
      return (
        <div className="eky-diwali-message-confirm-popup absolute w-screen h-screen z-[9] flex justify-center items-center">
          <div className="absolute w-full h-full bg-black z-[1] opacity-[50%]"></div>
          <div className="eky-popup-content max-w-[355px] w-full bg-[#F6F6F6] rounded-[10px] flex flex-col z-[2] p-6">
            <div className="mb-3">
              <p className="text-[#A9725D] text-[21px]">YOUR CODE</p>
            </div>
            <div>
              <p className="text-[#6C4537] text-[14px] font-inter">
                This is your unique code. You can find it written on your gift box.
              </p>
            </div>
            <div className="popup-btns flex flex-col mt-5 justify-center items-center">
              <button
                className="eky-diwali-button-popup-large continue"
                onClick={() => {
                  setShowGiftHelpPopup(false)
                  triggerEvent('info_close', 'receive_your_gift', '01', '/enter_code', 'Enter Your Unique Code')
                }}
                onTouchStart={e => {
                  e.currentTarget.classList.toggle('press')
                }}
                onTouchEnd={e => {
                  e.currentTarget.classList.toggle('press')
                }}
              >
                <p className="eky-button-text-main">GOT IT</p>
              </button>
              <button
                className="eky-diwali-button-popup-large my-3 back"
                onClick={() => {
                  setHasCheckedCode(false)
                  navigateToPage('landing')
                  setShowGiftHelpPopup(false)
                  triggerEvent('dont_have_code', 'receive_your_gift', '01', '/enter_code', 'Enter Your Unique Code')
                }}
                onTouchStart={e => {
                  e.currentTarget.classList.toggle('press')
                }}
                onTouchEnd={e => {
                  e.currentTarget.classList.toggle('press')
                }}
              >
                <p className="eky-button-text-main">DON't HAVE A CODE?</p>
              </button>
            </div>
          </div>
        </div>
      )
    }

    const codeHasBeenCheckedText = () => {
      if (!hasCheckedCode) return

      if (validUniqueCodeInput) {
        return (
          <div className="eky-diwali-valid-unique-code-text z-[5] fixed bottom-[55%]">
            <p className="text-white text-[24px] text-center">
              LOOKS GOOD.<br></br>LET'S CELEBRATE!
            </p>
          </div>
        )
      } else {
        triggerEvent('try_again', 'receive_your_gift', '01', '/enter_code', 'Enter Your Unique Code')

        return (
          <div className="eky-diwali-invalid-unique-code-text z-[5] fixed bottom-[55%]">
            <p className="text-white text-[24px] text-center">
              CODE NOT RECOGNISED.<br></br>PLEASE TRY AGAIN
            </p>
          </div>
        )
      }
    }

    const navigateToExperienceLight = (eventLog, journey, stepIndex, pagePath, pagetitle) => {
      triggerEvent(eventLog, journey, stepIndex, pagePath, pagetitle)

      const linkEl = document.createElement('a')
      linkEl.href = finalExperienceURL
      linkEl.rel = 'noreferrer'

      if (window.open) {
        window.open(finalExperienceURL, '_blank')
      } else {
        linkEl.target = '_blank'
        linkEl.click()
      }
    }
    return (
      <div className={`eky-diwali-recieve-gift flex flex-col items-center w-full h-screen relative`}>
        {showGiftHelpPopup && helpPopup()}
        <img
          className="absolute bottom-0 w-full"
          src="https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/background-mandala.png"
          alt=""
        />
        <div className="text-[32px] text-white uppercase text-center px-[1rem] py-3 mt-8 leading-[36px] z-[2] bg-[#7B4797] w-full border-y-4 border-[#E95E0F] flex justify-center items-center relative">
          <p className="max-w-[200px]">ENTER YOUR UNIQUE CODE</p>
          <img
            className="w-[35px] h-[35px] absolute top-[-7px] right-[-7px] m-4 z-[5]"
            src="https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/help-icon.svg"
            alt="get help for this page"
            onClick={e => {
              setShowGiftHelpPopup(true)
              triggerEvent('info_open', 'receive_your_gift', '01', '/enter_code', 'Enter Your Unique Code')
            }}
          />
          <img
            className="w-[35px] h-[35px] absolute top-[-7px] left-[-7px] m-4 z-[5]"
            src="https://cdn.pointandplace.com/services/dewars-diwali-app/2024/assets/back-button-icon.svg"
            alt="go back to landing page"
            onClick={e => {
              navigateToPage('landing', 'back', 'receive_your_gift', '01', '/enter_code', 'Enter Your Unique Code')
            }}
          />
        </div>
        {codeHasBeenCheckedText()}
        <div
          className={`unique-code my-4 z-[2] flex flex-col w-full max-w-[25rem] justify-center items-center p-4 fixed bottom-0 top-[60px] ${
            validUniqueCodeInput ? 'eky-diwali-valid-unique-code' : 'eky-diwali-invalid-unique-code'
          }`}
        >
          <CodeEntry
            SetValidUniqueCodeInput={SetValidUniqueCodeInput}
            setShowSpinner={setShowSpinner}
            setFinalExperienceURL={setFinalExperienceURL}
            dispatch={dispatch}
            designVersion={designVersion}
            setHasCheckedCode={setHasCheckedCode}
            setUniqueCode={setUniqueCode}
            triggerEvent={triggerEvent}
          />

          {/* <p className="text-[23px]">Enter your code</p>
          <div className="flex flex-col w-full h-full text-center justify-center items-center">
            <input
              className="text-center w-[70%] text-[80px] leading-none border-b-2 border-black eky-diwali-unique-code-input"
              type="text"
              name=""
              id=""
              maxLength={5}
              placeholder="please enter your five digit code*"
              onChange={e => validateUniqueCodeAndNavigateToExperience(e.target.value)}
            />
          </div> */}
        </div>

        <div className="nav-buttons w-full mt-4 z-[2] flex justify-center mb-[5rem]">
          <div className="flex flex-col items-center">
            {/* <button
              className="eky-diwali-button-small"
              onClick={() => {
                validateUniqueCodeAndNavigateToExperience()
              }}
            >
              <p className="eky-button-text-main">Continue</p>
            </button> */}

            {validUniqueCodeInput && (
              <>
                <button
                  className="eky-diwali-button-large my-2 fixed bottom-[30px]"
                  onClick={() =>
                    navigateToExperienceLight(
                      'start_experience',
                      'receive_your_gift',
                      '02',
                      '/code_successful',
                      'Looks Good Lets Celebrate'
                    )
                  }
                  onTouchStart={e => {
                    e.currentTarget.classList.toggle('press')
                  }}
                  onTouchEnd={e => {
                    e.currentTarget.classList.toggle('press')
                  }}
                >
                  <p className="eky-button-text-main">START EXPERIENCE</p>
                </button>
              </>
            )}

            {!validUniqueCodeInput && (
              <>
                <button
                  className="eky-diwali-button-large-blur my-2 fixed bottom-[30px]"
                  onClick={() =>
                    navigateToPage(
                      'landing',
                      'dont_have_code',
                      'receive_your_gift',
                      '01',
                      '/enter_code',
                      'Enter Your Unique Code'
                    )
                  }
                  onTouchStart={e => {
                    e.currentTarget.classList.toggle('press')
                  }}
                  onTouchEnd={e => {
                    e.currentTarget.classList.toggle('press')
                  }}
                >
                  <p className="eky-button-text-main">Don't have a code?</p>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }

  //
  //  ==================================================================================================================
  //
  //  :main render:
  return (
    <>
      <Content>
        <div className="hidden md:flex">
          <DesktopLandingPage></DesktopLandingPage>
        </div>
        <div className="flex flex-col w-full h-screen md:hidden">
          {showSpinner && <Spinner></Spinner>}
          {isLanding() && landing()}
          {isPersonalise() && personalise()}
          {isCheckYourMessage() && checkYourMessage()}
          {isAddPhoto() && addPhoto()}
          {isCropPhoto() && cropPhoto()}
          {isAllSet() && allSet()}
          {isReceiveGift() && receiveGift()}
          <img src="" id="cropped-image" alt="" className={`${isCropPhoto() === false ? 'hidden' : ''}`} />
        </div>
      </Content>
    </>
  )
}
