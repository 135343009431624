/*

  l a y o u t
  Layout

  :description:
  The layout component functions as our top-level container.

  The <Outlet> is a component that is filled in by react router.

*/

//
//  :react & redux:
import React from "react";
import { Outlet } from "react-router-dom";

//
//  :components:
import { Page } from "./Page";

//
//  :component:
export const Layout = (props) => {

  const COLOURS = {
    background: "bg-white",
    text: "text-black"
  }

  return (
    <div className={`eky-layout w-full min-h-screen flex-col ${COLOURS.background} ${COLOURS.text}`}>
      <Page>
        <Outlet />
      </Page>
    </div>
  )
}